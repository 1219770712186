/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap'); */
@import url("https://cdnjs.cloudflare.com/ajax/libs/Swiper/11.0.3/swiper-bundle.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/css/bootstrap.min.css");
@import url("https://site-assets.fontawesome.com/releases/v6.4.0/css/all.css");
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --color-primary: #6db44b;
  --color-primary-hover: #5c9e3d;
  --color-green: #76a713;
  --color-secondary: #f19020;
  --color-orange-light: #fcb430;
  --color-danger: #e72327;
  --color-grey-0: #fff;
  --color-grey-50: #f9fafb;
  --color-grey-100: #f3f4f6;
  --color-grey-200: #e5e7eb;
  --color-grey-300: #d1d5db;
  --color-grey-400: #9ca3af;
  --color-grey-500: #6b7280;
  --color-grey-600: #4b5563;
  --color-grey-700: #374151;
  --color-grey-800: #1f2937;
  --color-grey-900: #212529;
  --color-dark: #191919;
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-md: 1.125rem;
  --text-lg: 1.25rem;
  --text-xl: 1.5rem;
  --text-2x: 1.75rem;
  --text-3x: 2rem;
  --text-4x: 2.25rem;
  --border-radius-sm: 0.25rem;
  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;
  --shadow-sm: 0px 2px 6px 0px rgba(40, 40, 40, 0.1);
  --shadow-md: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  --shadow-lg: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.12);
  --padding-section: 4rem;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 50px;
  height: 4px;
  background-color: var(--color-orange-light);
  border-radius: 20px;
  margin-right: 5px;
}

.section-title {
  position: relative;
  z-index: 1;
  font-size: 2.5rem;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Exo 2", sans-serif;
  direction: ltr;


}

body[dir='rtl'] {
  direction: rtl;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.section-title {
  font-size: 30px;
  margin: 35px 0;

}


/* header */
.header-container {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 70px;
}

.logoImg {
  width: 150px;
}


.navbar-custom {
  transition: background-color 0.3s ease;
}

.navbar-custom.navbar-scrolled {
  background-color: #fff !important;
}

.activeColor {
  font-size: 20px;
  font-weight: bold;
  color: var(--color-primary);
}

.NonactiveColor {
  font-size: 17px;
  font-weight: 500;
  color: var(--color-grey-600);
}

.flag-btn {
  border: 0px;
}



.flagImg {
  width: 70px;
  height: 45px;
  border-radius: 5px;
}

.dropdown-toggle::after {
  margin-right: 10px;
}

.dropdown-menu {
  padding: 0px;
  margin: auto;
  text-align: center;
}


.dropdown-item:active {
  background-color: var(--color-grey-100);
}

.navbar-toggler {
  color: var(--color-grey-100);
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  box-shadow: 0;
  color: var(--color-grey-50);

}

/* slider  */

.slider-container {}

.swiper-button-prev,
.swiper-button-next {
  color: var(--color-grey-600);
  font-weight: 300;
  font-size: 50px;
}

.swiper-button-prev {
  left: 3%;

}

.swiper-button-next {
  right: 3%;
}

.swiper-button-lock{
  display: block;
}

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;

}

.content-Slider {
  top: 35%;
  left: 15%;
}

[dir=rtl] .content-Slider {
  right: 15%;
}



.title-content {
  font-size: 50px;
}

.text-slugon {
  color: var(--color-primary-hover);
  font-weight: 400;
}

.text-slider {
  color: var(--color-grey-600);
  line-height: 2;
}

.contact-slider {
  color: var(--color-grey-0);
  background-color: var(--color-primary);
  padding: 10px 24px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.2ch;

}

.contact-slider:hover {
  background-color: var(--color-primary-hover);
}

.img-slider {
  width: 650px;
}

@media screen and (max-width:500px) {
  .content-Slider {
    display: none;
  }

  .imgSlider-container{
    margin: auto;
    text-align: center;
  }


}

@media screen and (max-width: 768px) {
  .img-slider {
    width: 300px;
    margin: auto;

  }

  .content-Slider {
    top: 25%;
  }

  .title-content {
    font-size: 20px;
  }

  .text-slider {
    line-height: 1.4;
  }

  .navbar-nav li{
    margin-top: 10px;
  }

  .items-footer{
    text-align: center;
  }


}


.background-slider {
  background-color: var(--color-grey-100);
}

@media screen and (max-width: 768px) {}

/* about us */
.aboutImg {
  width: 500px;
  height: 500px;
  border-radius: 10px;
}

.about-paragraph {
  color: var(--color-grey-600);
  text-align: start;
}

[dir=rtl] .about-paragraph .heading-Catagory .about-text {
  text-align: end;
}

.aboutContact {
  color: var(--color-grey-900);
  font-weight: bold;
  margin-top: 10px;
}

.whatsappImg {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 320px) {
  .aboutImg {
    width: 200px;
    /* Set maximum width to 500px */
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .aboutImg {
    width: 300px;
    /* Set maximum width to 500px */
    height: auto;
  }
}




/* catagories */
.catagoryConatiner {
  text-align: center;
}

.product-catagory {
  color: var(--color-primary);
}

.heading-Catagory {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.catagory-containerSlider {
  padding-bottom: 20px;
  padding-top: 15px;
  width: 350px;
  height: auto;
  margin: auto;
  background-color: var(--color-grey-100);
  border-radius: 10px;
}

.catagory-img {
  width: 250px;
  height: 250px;
  margin: auto;
  display: flex;
}

.catagory-title {
  font-weight: bold;
  font-size: 20px;
  color: var(--color-grey-600);
}

/* certificate */
.certificateImage {
  margin: auto;
  text-align: center;
  padding: 20px 0;
}

/* clints Logo */
.container-clients {
  background-color: var(--color-grey-50);
}

.logo-img {
  width: 300px;
  height: auto;
}
.client-img{
  width: 220px;
  height: auto;
  border-radius: 50%;
}


/* footer */

.footer {
  background-color: rgb(15 23 42)
}

.logoFooter {
  width: 220px;
  height: 120px;
  filter: brightness(0) invert(1);
  margin-bottom: 20px;

}

.InfoFooter {
  width: 80%;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 40px;
}

.divider {
  width: 119px;
  color: #e69d00;
  padding-top: 1px;

}

.headline-footer {
  font-weight: 600;
  color: var(--primary_Color_Blue);
}

.link-footer {
  font-size: 15px;
  font-weight: 600;

  a {
    color: white;

  }
}

.link-footer a:hover {
  color: var(--color-primary);
}

.link-footer ul li i {
  color: var(--color-primary);
  font-size: 20px;
}


.elqubasy {
  text-align: left;
  margin-top: 10px;
  margin-left: 100px;
  font-size: 15px;
  font-weight: 600;

}


/* call to action */

.social-links-fixed ul {
  position: fixed;
  left: 30px;
  bottom: 40px;
  justify-content: space-between;
  z-index: 99999;
  background-color: transparent;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 50px;
  height: fit-content;
  transition: all 0.5s ease 0s;
  background-color: transparent;
}

@media (max-width:360px) {
  .social-links-fixed ul {
    left: 30px;
  }
}

.social-links-fixed ul li {
  width: 50px;
  height: 50px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 50px;
  transition: all 0.5s ease 0s;
}

.social-links-fixed ul li.instagam {
  background-color: #833AB4;
}

.social-links-fixed ul li.linkedin {
  background-color: #0077B5;
}

.social-links-fixed ul li.twitter {
  background-color: rgb(29, 161, 242);
  margin-bottom: 0px;
}

.social-links-fixed ul li.facebook {
  background: linear-gradient(155deg, rgb(0, 39, 85), rgb(0, 159, 255));
}

.social-links-fixed ul li.pinterest {
  background-color: rgb(41, 128, 211);
}

.social-links-fixed ul li.instagram {
  background: linear-gradient(45deg, rgb(240, 148, 51) 0%, rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136) 100%);
}

.social-links-fixed ul li.whatsapp {
  background: linear-gradient(45deg, rgb(45, 182, 64), rgb(80, 203, 94));
}

.social-links-fixed ul li a {
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.social-links-fixed li {
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
}

.social-links-fixed ul {
  height: auto;
  box-shadow: none;
}

.social-links-fixed li.support {
  transform: scale(1) translatey(55px);
  visibility: visible;
  opacity: 1;
  background-color: var(--color-primary);
  transition: all 0.5s ease 0s;
}

.social-links-fixed.open li.support {
  transform: scale(1) translateY(0px);
  background-color: rgb(242, 104, 51);
}

.social-links-fixed.open li.support span {
  transform: rotateY(180deg);
}

.social-links-fixed li span {
  transition: all 0.5s ease 0s;
}

.social-links-fixed.open li {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}

.social-links-fixed.open ul {
  box-shadow: rgba(0, 0, 0, 0.34) 0px 1px 20px 0px;
  background-color: white;
}